.rs-picker-select-menu-item 
{
  position: relative;

  .icon:first-child
  {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);	
    margin-top: -1px;
  }

  .num
  {
    // fake icon width for missing icons
    margin-left: 20px;
  }

  .you
  {
    color: @color-hilite;

    .icon-avatar
    {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY( -50% );
    }
  }
}
