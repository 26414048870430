@font-dir: '../assets/fonts/';

/*
  Icomoon icon variables
*/

@icon-abarth: "\e9a9";
@icon-acura: "\e9b2";
@icon-alfa-romeo: "\e9aa";
@icon-alpine: "\e9ac";
@icon-arrow-down: "\e900";
@icon-arrow-up: "\e911";
@icon-audi: "\e90a";
@icon-avatar: "\e901";
@icon-bell: "\e902";
@icon-bmw: "\e91e";
@icon-calendar: "\e903";
@icon-car: "\e904";
@icon-check: "\e905";
@icon-chevrolet: "\e9b5";
@icon-chrysler: "\e9b9";
@icon-citroen: "\e91f";
@icon-classic-mini: "\e91d";
@icon-clock: "\e94e";
@icon-clock1: "\e906";
@icon-cone: "\e907";
@icon-cross: "\ea0f";
@icon-dacia: "\e9ab";
@icon-exclamation-triangle: "\e908";
@icon-eye: "\e909";
@icon-ferrari: "\e928";
@icon-fiat: "\e920";
@icon-flag: "\e916";
@icon-ford: "\e921";
@icon-fury: "\e922";
@icon-hashtag: "\e90b";
@icon-home: "\e90c";
@icon-honda: "\e923";
@icon-jaguar: "\e9af";
@icon-jeep: "\e9b7";
@icon-kia: "\e9b8";
@icon-lamborghini: "\e9c0";
@icon-landrover: "\e9b0";
@icon-lexus: "\e9b6";
@icon-mazda: "\e924";
@icon-mercedes: "\e9c1";
@icon-mg: "\e91a";
@icon-mini: "\e919";
@icon-minicooper: "\e925";
@icon-mitsubishi: "\e9b1";
@icon-mnr: "\e91b";
@icon-nissan: "\e9b4";
@icon-nsu: "\e926";
@icon-opel: "\e92b";
@icon-page-end: "\e915";
@icon-page-next: "\e914";
@icon-page-prev: "\e912";
@icon-page-start: "\e913";
@icon-peugeot: "\e92c";
@icon-porsche: "\e927";
@icon-rebel: "\e90d";
@icon-refresh: "\e90e";
@icon-renault: "\e956";
@icon-seat: "\e957";
@icon-skoda: "\e918";
@icon-smart: "\e9ad";
@icon-star: "\e90f";
@icon-stopwatch: "\e910";
@icon-subaru: "\e958";
@icon-suzuki: "\e917";
@icon-sylva: "\e959";
@icon-tesla: "\e9b3";
@icon-toyota: "\e95a";
@icon-triumph: "\e91c";
@icon-vauxhall: "\e9ba";
@icon-volvo: "\e9ae";
@icon-vw: "\e95b";
@icon-westfield: "\e95c";

/*
  Icomoon font face declaration
*/

@font-face {
  font-family: 'icomoon';
  src:  url('@{font-dir}/icomoon.eot?6lc1br');
  src:  url('@{font-dir}/icomoon.eot?6lc1br#iefix') format('embedded-opentype'),
    url('@{font-dir}/icomoon.ttf?6lc1br') format('truetype'),
    url('@{font-dir}/icomoon.woff?6lc1br') format('woff'),
    url('@{font-dir}/icomoon.svg?6lc1br#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/*
  Icomoon base class
*/


.icon()
{
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], 
[class*=" icon-"] 
{
  .icon(); 
}

/*
  Icomoon icons for RSuite overwrites
*/

.rs-icon-page-previous:before 
{
  content: @icon-page-prev;
}

.rs-icon-page-next:before 
{
  content: @icon-page-next;
}

.rs-icon-page-end:before 
{
  content: @icon-page-end;
}

.rs-icon-page-top:before  
{
  content: @icon-page-start;
}

.icon-flag-checkered:before 
{
  content: @icon-flag;
}

.icon-close:before 
{
  content: @icon-cross;
}

/*
  Icomoon extra icons
*/

.icon-austin:before ,
.icon-mini-classic:before 
{
  content: @icon-classic-mini;
}

.icon-volkswagen:before 
{
  content: @icon-vw;
}

.icon-fisher:before 
{
  content: @icon-fury;
}

/*
  Icomoon icons, copied from the Icomoon zip file
*/

.icon-suzuki {
  &:before {
    content: @icon-suzuki; 
  }
}
.icon-flag {
  &:before {
    content: @icon-flag; 
  }
}
.icon-alfa-romeo {
  &:before {
    content: @icon-alfa-romeo; 
  }
}
.icon-dacia {
  &:before {
    content: @icon-dacia; 
  }
}
.icon-alpine {
  &:before {
    content: @icon-alpine;     
    color: #006fba;
  }
}
.icon-smart {
  &:before {
    content: @icon-smart; 
  }
}
.icon-volvo {
  &:before {
    content: @icon-volvo; 
  }
}
.icon-jaguar {
  &:before {
    content: @icon-jaguar; 
  }
}
.icon-landrover {
  &:before {
    content: @icon-landrover; 
  }
}
.icon-mitsubishi {
  &:before {
    content: @icon-mitsubishi; 
  }
}
.icon-acura {
  &:before {
    content: @icon-acura; 
  }
}
.icon-tesla {
  &:before {
    content: @icon-tesla; 
  }
}
.icon-nissan {
  &:before {
    content: @icon-nissan; 
  }
}
.icon-chevrolet {
  &:before {
    content: @icon-chevrolet; 
  }
}
.icon-lexus {
  &:before {
    content: @icon-lexus; 
  }
}
.icon-jeep {
  &:before {
    content: @icon-jeep; 
  }
}
.icon-kia {
  &:before {
    content: @icon-kia; 
  }
}
.icon-chrysler {
  &:before {
    content: @icon-chrysler; 
  }
}
.icon-vauxhall {
  &:before {
    content: @icon-vauxhall; 
  }
}
.icon-abarth {
  &:before {
    content: @icon-abarth; 
  }
}
.icon-lamborghini {
  &:before {
    content: @icon-lamborghini; 
  }
}
.icon-triumph {
  &:before {
    content: @icon-triumph; 
  }
}
.icon-classic-mini {
  &:before {
    content: @icon-classic-mini; 
  }
}
.icon-mnr {
  &:before {
    content: @icon-mnr; 
  }
}
.icon-audi {
  &:before {
    content: @icon-audi; 
  }
}
.icon-skoda {
  &:before {
    content: @icon-skoda; 
  }
}
.icon-mini {
  &:before {
    content: @icon-mini; 
  }
}
.icon-mg {
  &:before {
    content: @icon-mg; 
  }
}
.icon-nsu {
  &:before {
    content: @icon-nsu; 
  }
}
.icon-porsche {
  &:before {
    content: @icon-porsche; 
  }
}
.icon-ferrari {
  &:before {
    content: @icon-ferrari; 
  }
}
.icon-mercedes {
  &:before {
    content: @icon-mercedes; 
  }
}
.icon-bmw {
  &:before {
    content: @icon-bmw; 
  }
}
.icon-citroen {
  &:before {
    content: @icon-citroen; 
  }
}
.icon-fiat {
  &:before {
    content: @icon-fiat; 
  }
}
.icon-ford {
  &:before {
    content: @icon-ford; 
  }
}
.icon-fury {
  &:before {
    content: @icon-fury; 
  }
}
.icon-honda {
  &:before {
    content: @icon-honda; 
  }
}
.icon-mazda {
  &:before {
    content: @icon-mazda; 
  }
}
.icon-minicooper {
  &:before {
    content: @icon-minicooper; 
  }
}
.icon-opel {
  &:before {
    content: @icon-opel; 
  }
}
.icon-peugeot {
  &:before {
    content: @icon-peugeot; 
  }
}
.icon-renault {
  &:before {
    content: @icon-renault; 
  }
}
.icon-seat {
  &:before {
    content: @icon-seat; 
  }
}
.icon-subaru {
  &:before {
    content: @icon-subaru; 
  }
}
.icon-sylva {
  &:before {
    content: @icon-sylva; 
  }
}
.icon-toyota {
  &:before {
    content: @icon-toyota; 
  }
}
.icon-vw {
  &:before {
    content: @icon-vw; 
  }
}
.icon-westfield {
  &:before {
    content: @icon-westfield; 
  }
}
.icon-page-prev {
  &:before {
    content: @icon-page-prev; 
  }
}
.icon-page-next {
  &:before {
    content: @icon-page-next; 
  }
}
.icon-page-end {
  &:before {
    content: @icon-page-end; 
  }
}
.icon-page-start {
  &:before {
    content: @icon-page-start; 
  }
}
.icon-arrow-down {
  &:before {
    content: @icon-arrow-down; 
  }
}
.icon-arrow-up {
  &:before {
    content: @icon-arrow-up; 
  }
}
.icon-avatar {
  &:before {
    content: @icon-avatar; 
  }
}
.icon-bell {
  &:before {
    content: @icon-bell; 
  }
}
.icon-calendar {
  &:before {
    content: @icon-calendar; 
  }
}
.icon-car {
  &:before {
    content: @icon-car; 
  }
}
.icon-check {
  &:before {
    content: @icon-check; 
  }
}
.icon-clock1 {
  &:before {
    content: @icon-clock1; 
  }
}
.icon-cone {
  &:before {
    content: @icon-cone; 
  }
}
.icon-exclamation-triangle {
  &:before {
    content: @icon-exclamation-triangle; 
  }
}
.icon-eye {
  &:before {
    content: @icon-eye; 
  }
}
.icon-hashtag {
  &:before {
    content: @icon-hashtag; 
  }
}
.icon-home {
  &:before {
    content: @icon-home; 
  }
}
.icon-rebel {
  &:before {
    content: @icon-rebel; 
  }
}
.icon-refresh {
  &:before {
    content: @icon-refresh; 
  }
}
.icon-star {
  &:before {
    content: @icon-star; 
  }
}
.icon-stopwatch {
  &:before {
    content: @icon-stopwatch; 
  }
}
.icon-clock {
  &:before {
    content: @icon-clock; 
  }
}
.icon-cross {
  &:before {
    content: @icon-cross; 
  }
}