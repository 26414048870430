
.filters.rs-btn-toolbar
{
  display: flex;
  align-items: center;
  justify-content: center;
}

.rs-dropdown
{
  .rs-dropdown-item.you
  {
    &, a 
    {
      color: @color-hilite;
    }

    .icon
    {
      margin-left: 1em;
    }
  }

  .rs-btn 
  {
    padding-right: 10px;
  }
}

.rs-picker-select.rs-picker-custom,
.rs-picker-select-menu,
.rs-picker-popup
{
  max-width: 140px;

  .rs-picker-toggle-value,
  .rs-picker-toggle-caret
  {
    color: #575757;
  }
  
  
}

/*
.rs-picker-toggle
{
  .icon-arrow-down
  {
    position: relative;    
    width: 10px;
    height: 10px;
    display: inline-block;
    
    &::before
    {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-top: 1x;
    }  
  }

  &.rs-picker-toggle-active
  {
    .icon-arrow-down
    {
      transform: rotate( 180deg );
    }
  }
}
*/

.rs-picker-toggle-indicator
{
  display: none;
  svg
  {
    width: 2em;
  }
}