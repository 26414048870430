.chart
{
  position: relative;
}

.chart-tooltip
{
  position: absolute;
  z-index: 9999;
  background: white;
  padding: 5px 15px;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba( 0, 0, 0, 0.4);
  transform: translate( -50%, 12px);
  text-align: center;
  line-height: 1.6em;

  > div
  {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .car
  {
    font-weight: bold;
  }

  .car-logo
  {
    margin-top: -.2em;
  }

  .icon-stopwatch
  {
    display: inline-block;
    font-size: .9em;
    margin-top: -.3em;
  }

  &.you
  {
    .car
    {
      color: @color-red;
    }
  }

  // green for fast laps
  &.fast
  {
    &, &:before, .car
    {
      background: @color-fast-lap;
      color: white;
    }
  }

  // purple for fastest laps
  &.fastest
  {
    &, &:before, .car
    {
      background: @color-fastest;
      color: white;
    }
  }

  // orange fow invalid laps
  &.invalid
  {
    &, &:before, .car
    {
      background: @color-orange;
      color: white;
    }
  }

  // arrow
  &:before
  {
    content: "";
    display: block;
    position: absolute;
    left: calc( 50% - 4.2px );
    top: -4.2px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background: white;    
  }
}