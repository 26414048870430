.navbar
{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .rs-navbar-header
  {
    position: absolute;
    left: 1em;    
  }

  .rs-navbar-brand
  {
    padding: 0;
  }

  .logo
  {
    position: absolute;
    left: 10px;
    display: block;
    border-radius: 100%;
    overflow: hidden;
    background: white;
    width: 36px;
    height: 36px;
    margin-top: -2px;
    text-align: center;
    
    img
    {
      width: 88%;
      height: auto;
      object-fit: cover;
      position: relative;
      top: 50%;
      transform: translateY( -55% );
    }
  }

  .rs-navbar-item
  {
    position: relative;
    overflow: hidden;

    .icon
    {
      margin-top: -.2em;
      
      svg
      {
        fill: white;
      }
    }

    .icon,
    .label
    {
      color: white
    }

    // arrow on active item
    &:after
    {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -30px;
      width: 14px;
      height: 14px;
      background: white;
      transform: rotate( 45deg ) translateX( -50% );
      z-index: 999;
      opacity: 0;
      transition: opacity .3s, bottom .3s;
      margin-left: -2px;
      display: block;
    }
  }

  .rs-navbar-item-active:after
  {
    opacity: 1;
    bottom: -12px;
  }

  .you
  {
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY( -50% );
  }
}

@media screen and ( max-width: 430px )
{
  .navbar 
  {
    .rs-navbar-item 
    {
      .label
      {
        display: none;
      }

      .icon
      {
        margin: 0;
      }

      &:after
      {
        margin-left: -5px;
      }
    }
  }
}

@media screen and ( max-width: 320px )
{
  .navbar 
  {
    align-items: left;
    justify-content: left;

    .logo
    {
      display: none;
    }
  }
}