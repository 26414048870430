.chart
{
  margin: 1em 0;
  padding: 0;

  svg
  {
    overflow: visible;
  }

  > div,
  div[id^="reactgooglegraph"]
  {
    height: auto !important;
  }

  .tooltip
  {
    padding: 5px 10px;
    line-height:2em;
    text-align: center;
    font-size: .9em;

    b
    {
      font-size: 2.4em;
    }

    &.empty
    {
      font-size: 1em;
      padding: 5px;
      line-height: 1em;
    }
  }

  /*
  div[aria-label]
  {
    .show_table()  !important;

    table
    {
      background-color: white;
      border: 1px solid red;
      padding: 1em;
      position: fixed;
      left: 0;
      bottom: 0;
      th, td
      {
        padding: .5em;
      }
    }
  }
  */

  
}

#scatter-chart circle
{
  opacity: .7;
}

.show_table
{
  left: 0;
  width: auto;
  height: auto;
  overflow: visible;
  background-color: white;
  // position: relative;
  z-index: 9999;
}