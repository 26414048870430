@import './Icomoon.less';

i.icon
{
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  display: inline-block;
  text-transform: none;
  font-size: 14px;

  &:before
  {
    position: relative;
    display: inline-block;
    top: 1px;
    margin-right: 5px;
  }

  &.icon-arrow-up,
  &.icon-arrow-down
  {
    font-weight: bolder;
  }

  &.carret
  {
    margin-left: .3em;
    &:before
    {
      position: relative;
      top: .1em;
    }
  }

  &.active
  {
    color: @color-red;
  }

  &:first-child
  {
    //margin-right: .5em;
  }

  &:last-child  
  {
    //margin-left: .5em;
  }
}

.rs-dropdown-toggle-caret,
.rs-picker-toggle-caret
{
  display: none;
}

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn
{
  padding-right: 1em;
}