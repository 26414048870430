.rs-drawer-wrapper
{
  max-width: 100%;
  z-index: 9999;
}

.rs-drawer-body
{
  margin: 0;
  padding: 20px 20px 0 20px;
  height: ~"calc( 100vh - 70px )" !important;

  
  input[type="text"].rs-input
  {
    font-size: 16px;
  }
}

.rs-drawer-footer
{
  display: none; 
}

.rs-drawer-header
{
  background-color:@color-gray-light;
  border-bottom: 1px solid @color-gray-light;
  height: 56px;
  padding: 12px;

  .icon
  {
    margin-right: .4em;
  }

  .rs-drawer-title
  {
    text-align: right;
    padding-right: 10px;
  }

  .rs-drawer-header-close
  {
    top: 50%;
    transform: translateY( -50% );
    margin-top: 2px;

    svg
    {
      display: none;
    }
    
    &:before
    {
      font-family: 'icomoon' !important;
      content: "\ea0f";
    }
  }
}

.rs-drawer
{
  max-width: 100%;  
  z-index: 9999;

  &.rs-drawer-left.rs-drawer-sm,
  &.rs-drawer-right.rs-drawer-sm
  {
    width: 350px;
  }

  &.options-drawer
  {
    .help
    {
      margin: 1em 0 .5em 0;
      line-height: 1.7em;
      font-style: italic;
    }

    sup
    {
      color: @color-red;
      text-transform: uppercase;
      font-weight: bold;
    }

    .rs-form-group
    {
      &:not(:first-child)
      {
        border-top: 1px solid @color-gray-light;
        margin-top: 1em;
        padding-top: 1em;
      }

      &.credits
      {
        padding: 1.5em 0 1.5em 0;    
      }

      .icon
      {
        margin-right: .5em;
      }
    }

    .rs-control-label
    {
      font-weight: bold;
      margin: 1em 0;      
    }

    .rs-form-group .rs-input-group
    {
      width: 100%; 
    }

    .rs-btn,
    .rs-control-label
    {
      .icon
      {
        margin-right: .3em;
      }
    }

    .rs-input-group-focus .rs-btn
    {
      background-color: @color-red;
      color: white;
    }

    .labeled-toggle
    {
      width: 100%;
      position: relative;
      display: flex;
      margin: 2em 0;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      user-select: none;

      &, .rs-btn-toggle
      {
        opacity: 1;
        transition: opacity .3s;
      }

      &.disabled
      {
        pointer-events: none;
        cursor: default;
        opacity: .7;

        .rs-btn-toggle
        {
          opacity: .3;
        }
      }
    }
  }

  .you 
  {
    .rs-input-group
    {
      margin-top: 1em;
    }

    .icon-check:before
    {
      top: 0;
    }
  }
}