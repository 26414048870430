@import 'icomoon.less';

.data-table
{
  &.sortable thead th,
  &.clickable tbody tr
  {
    cursor: pointer;
  }

  thead
  {
    user-select: none;
  }

  th
  {
    text-align: left;
    white-space: nowrap;
    font-weight: 300;
    font-size: 1em;

    > .icon    
    {
      margin-right: .7em
    }

    .label
    {
      margin-right: .2em;
    }

    .sort
    {
      color: @color-hilite;
      width: 14px;
      height: 22px;
      text-align: center;
      position: relative;
      display: inline-block;
      top: 6px;

      .asc,
      .desc
      {
        position: absolute;
        margin: 0;
        left: 50%;

        &:before
        {
          top: 0;
        }
      }

      .asc
      {
        bottom: 0; 
      }

      .desc
      {
        top: 0;
      }

      &.asc .desc,
      &.desc .asc
      {
        color: @color-gray-medium;
      }
    }
  }  
}

/*
Pagination toolbar
*/
.rs-pagination
{
  margin: @cell-padding 0;
  padding: 0;
  position: relative;
  display: table;
  left: 50%;
  transform: translateX( -50% );
}