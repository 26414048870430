/*
Modal lives outside of the app root
*/
.rs-modal-backdrop.in
{
  opacity: .5;
}

.rs-modal-wrapper
{
  overflow: hidden;
  z-index: 9999;

  // fullsize modal to allow dialog to be centered
  .rs-modal
  {
    width: 100vw;
    height: 100vh;
    margin: 0;
  }

  // center dialog and not modal itself otherwise the opening animations gets screwed up
  .rs-modal-dialog
  {
    width: 600px;
    max-width: 100vw;
    max-height: 100vh;
    margin: 0;
    padding: 20px;
    min-height: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% ) !important;
    overflow: auto;
  }

  .rs-modal-content
  {
    overflow: hidden;
  }

  .rs-modal-body
  {
    max-height: calc( 100vh - 100px ) !important;
  }

  .rs-modal-header
  {
    margin: -20px;
    background-color: @color-hilite;
    padding: 20px;

    *
    {
      color: white
    }
  }

  .rs-modal-title   
  {
    > *
    {
      vertical-align: middle;
    }

    .icon
    {
      font-size: 1.1em;
      vertical-align: text-bottom;

      &:not(:first-child)
      {
        margin-left: 20px;
      }
    }
  }

  .rs-modal-footer
  {
    text-align: center;
  }

  // fix icomoon icon
  .rs-modal-header-close
  {
    z-index: 99;
    display: block;
    width: 50px;
    padding: 21px;
    top: 2px;
    right: 5px;
    font-family: 'icomoon' !important;
    font-size: 16px;

    &:before
    {
      content: "\ea0f";
    }

    svg
    {
      display: none;
    }
  }

  .help
  {
    color: #888;
    margin: 1em .5em 0 .5em;
  }
}