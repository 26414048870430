.rs-btn-toolbar
{
  text-align: center;
  margin: 0;
  min-height: 42px;

  > *
  {
    vertical-align: top;
    margin: 0 .5em .5em .5em;

    &:first-child
    {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .rs-dropdown-toggle, .rs-dropdown-toggle.rs-btn
  {
    margin-bottom: .4em;
  }

  + .rs-btn-toolbar
  {
    margin-top: 1em;
  }
}

.rs-btn.reset 
{
  .icon
  {
    margin-right: .3em;
  }
}

@media screen and ( max-width: 430px )
{
  .homepage button.reset 
  {
    .icon
    {
      margin: 0;
    }
    
    .label
    {
      display: none;
    }
  }
}