.car-modal
{
  .data-table .active
  {
    color: inherit;
  }

  .rs-modal-title 
  {
    display: flex;
    align-items: anchor-center;   
    
    

    .num + .model
    {
      &::before
      {
        content: "-";
        display: inline-block;
        margin: 0 .3em;
      }
    }

    .icon
    {
      margin: 0 5px 0 15px;
      padding: 0;
    }

    // logo size tweaks
    .car-logo
    {
      margin: 0 5px 0 0;
      height: 20px;

      &:before
      {
        position: relative;
      }

      &.icon-mnr,
      &.icon-fury,
      &.icon-fisher,
      &.icon-subaru
      {
        &:before
        {
          font-size: 1.5em;
          top: -.2em
        }
      }
      
      &.icon-mini
      {
        &:before
        {
          font-size: 1.4em;
          top: -.12em;
        }
      }
      
      &.icon-peugeot,
      &.icon-citroen
      {
        &:before
        {
          font-size: .8em;
          top: -.1em;
        }
      }

      &.icon-classic-mini,
      &.icon-mini-classic
      {
        &:before
        {
          font-size: 1.2em;
          top: -.1em;
        }
      }
    }

    .icon-flag,
    .laps,
    .icon-cone,
    .cones
    {
      display: none;
    }
  }
}