.rs-toast-container
{
  top: 70px !important;
  bottom: 0;
  right: 10px;
  padding: 10px;
  height: ~"calc( 100vh - 80px )";
  flex-direction: column-reverse;

  .rs-notification
  {
    margin-top: 10px;
    width: 300px;
    max-width: 100%;    
    text-align: center;
    position: relative;

    .rs-notification-content
    {
      background-color: var( --active-color);      
    }

    &.type-error 
    {
      --active-color: @color-red;

      *
      {
        color: white;
      }
    }

    &.type-success
    {
      --active-color: @color-green;

      *
      {
        color: white;
      }
    }

    &.type-warning 
    {
      --active-color: @color-orange;

      *
      {
        color: white;
      }
    }

    &.fast-day
    {
      --active-color: @color-purple;

      *
      {
        color: white;
      }
    }

    // no need to show previous laptime defails for yous
    &.context-you .prev
    {
      display: none;
    }
  }

  .rs-notification-content
  {
    position: relative;

    &:after
    {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient( 90deg, rgba( 255, 255, 255, .15 ) 0,  rgba( 255, 255, 255, 0 ) 100% );
      pointer-events: none;

      animation-name: notification_narrower;
      animation-duration: var( --duration );
      animation-fill-mode: both;
      animation-timing-function: linear;
    }    

    > .rs-notification-title
    {
      font-weight: bold;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba( 255, 255, 255, .7 );
    }

    > .rs-notification-description
    {
      padding: 15px 20px;
    }

    .timediff
    {
      display: inline-block;
      padding: 4px 10px;
      margin-top: 5px;
      background-color: white;
      border-radius: 4px;
      color: var( --active-color);
    }
  }

  .rs-notification-description,
  .rs-notification-title + .rs-notification-description
  {
    margin: 0;
  }

  .rs-btn-close
  {
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;

    .rs-icon
    {
      display: none !important;
    }
  }

  .laptime-new
  {
    font-size: 4em;
    font-weight: bold;
    letter-spacing: -1px;
    margin: 0;
    line-height: 1em;
  }

  .car
  {
    margin: 0;
    text-align: center;
  }

  p.car
  {
    font-size: 1.2em;
  }

  .laptime-new + .car
  {
    margin: 5px 0;
  }

  .icon
  {
    margin-top: -2px;
  }

  .wrap
  {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    > :first-child
    {
      justify-self: left;
      text-align: center;
    }

    > :last-child
    {
      justify-self: right;
      text-align: center;
    }
    
  }

  .next
  {
    font-size: 1.3em;

    .icon
    {
      font-size: .9em;
      margin-top: -2px;
    }

    .timediff 
    {
      justify-self: right;
      text-align: center;
    }
  }

  .prev
  {
    font-size: 1.2em;
    opacity: .7;
    margin-top: 10px;
    border-top: 1px solid white;
    padding-top: 10px;
    text-align: center;

    span:not(:first-child)
    {
      padding-left: 1em;
      
    }
  }

  .you .next 
  {
    margin: 0;
    .timediff
    {
      text-align: center;
      width: 100%;
    }
  }
}

@media screen and ( max-width: 330px )
{
  .rs-notification
  {
    width: 100%;   
    max-width: 100%;
    right: 0 !important;
    left: 0 !important;
    padding: 0 10px;

    .rs-notification-item-wrapper
    {
      width: 100%;
      max-width: 100%;

      .wrap
      {        
        justify-content: space-around;
      }

      *, 
      &.you .next .timediff
      {
        text-align: center;
      }

      .laptime-new
      {
        font-size: 16vw;
      }

      .next
      {
        font-size: 5vw;
      }

      .prev
      {
        font-size: 4vw;

        .icon
        {
          font-size: 3vw;
        }

        span:not(:first-child)
        {
          padding-left: .5em;
        }
      }
    }
  }
}



@keyframes notification_narrower
{
  from { left:0; }
  to { left: 100%; }
}