@base-color: '#FF0000';
@font-family-icon: 'icomoon';
@icon-font-path: '../../src/assets/fonts/';
@font-family-base: "'Roboto', sans-serif;";

// less variables

@color-gray-lighter: #f7f7fa;
@color-gray-light: #f2f2f5;
@color-gray-medium: #e5e5ea;
@color-gray-dark: #575757;

@color-red: #ff0000;
@color-green: #008000;
@color-green-bright: #00e400;
@color-blue: blue;
@color-orange: #ffa500;
@color-purple: #a500a5;

@color-hilite: @color-red;
@color-fastest: @color-purple;
@color-fastest-lap: @color-purple;
@color-fast-lap: @color-green;
@color-cone: @color-orange;

@cell-padding: 10px;

//@icon-font-path: './assets/fonts';
@font-family-icon: '../../fonts'; // 'rsuite-icon-font'
@base-color: @color-red;
@font-family-icon: 'icomoon';
@icon-font-path: '../../src/assets/fonts/';

/*
html, body, #root, .WimedoApp
{  
  min-height: 100%;
  height: 100%;

  // this fixes rendering issues with notifications
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
*/

:root
{
  // @H100~@H900
  --rs-primary-100: @color-gray-medium;
  --rs-primary-200: @color-red;
  --rs-primary-300: @color-red;
  --rs-primary-400: @color-red;
  --rs-primary-500: @color-red;
  --rs-primary-600: @color-red;
  --rs-primary-700: @color-red;
  --rs-primary-800: @color-red;
  --rs-primary-900: @color-red;

  // darkish grey with opacity
  --rs-navbar-inverse-hover-bg: #00000022;

  --rs-navbar-inverse-selected-bg: @color-red;

  --rs-state-focus-shadow: none;  
  --rs-color-focus-ring: transparent;

  --rs-btn-default-hover-text: black;

  // @table-color-color: @B400
  --rs-border-secondary: var(--rs-gray-400);

  // @table-head-font-color: @B900
  --rs-text-secondary: var(--rs-gray-900);

  // @text-color: @B900
  --rs-text-primary: var(--rs-gray-900);
}

.WimedoApp
{
  // css variables
  --cell-padding: @cell-padding;
  --color-hilite: @color-hilite;
  --color-grey-light: @color-gray-light;

  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;

  // components
  @import './Summary.less';
  @import './SortTable.less';
  
  @import './Charts.less';

  // rSuite overwrites
  @import './rsuite.less';

  // elements
  @import './Nav.less';

  // pages
  @import './Home.less';

  /*
   generic styles
  */

  .main-content
  {   
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
  }

  .wimedo-logo
  {
    margin: 1em auto 2em auto;
    display: block;
    max-width: 94%;
  }

  .rs-header
  {
    margin: 0 .2em;
  }

  .rs-container
  {
    margin: 1em 0;
  }

  .rs-content
  {
    margin: 0 1em;
  }

  // hilite active icons
  .icon
  {
    transition: color .3s;

    &.active
    {
      color: @color-hilite;
    }
  }
}

/*
These components live outside of the app root
Add some more importance over default styles by prefixing the body tag
*/
body
{
  @import './Icon.less';
  @import './OptionsDrawer.less';
  @import './Notifications.less';
  @import './SelectPicker.less';
  @import './Modal.less';
  @import './CarModal.less';  
  @import './FilterDropdown.less';
  @import './DataTable.less';
  @import './ChartTooltip.less';
}

@media screen and ( max-width: 400px )
{
  .WimedoApp
  {
    // responsive cell padding
    --cell-padding: 0;

    .rs-content
    {
      margin: 0 .5em;
    }
  }
}
@base-color: #FF0000;@font-family-icon: icomoon;@icon-font-path: ../../src/assets/fonts/;@font-family-base: 'Roboto', sans-serif;