// data table
.data-table
{
  width: 100%;

  .empty-data
  {
    margin: 10px auto;
    text-align: center;
  }

  td, th
  {
    padding: @cell-padding;
  }

  tbody 
  {
    tr
    {
      border-top: 1px solid @color-gray-light;
      margin-top: @cell-padding;
      padding: 0;
      background: transparent;
      transition: background 1s;

      &.penalty,
      &.comment
      {
        color: @color-orange;
      }

      &.hilite
      {
        font-weight: bold;
      }

      // special rows have a full-width background
      &.pos-1,
      &.fast,
      &.fastest
      {
        td
        {          
          color: white;
          font-weight: bold;

          // border radius can ony be set on td, not on tr
          @fastest-border-radius: 10px;          

          &:first-child
          {
            border-radius: @fastest-border-radius 0 0 @fastest-border-radius;                
          }

          &:last-child
          {
            border-radius: 0 @fastest-border-radius @fastest-border-radius 0;
          }
        }
      }

      // fastest laps have a stopwatch icon
      &.fastest
      {
        td:first-of-type
        {
          position: relative;

          &:before
          {
            .icon();
            content: @icon-stopwatch;
            position: absolute;
            // this aligns the icon with the stare icon in the table header
            left: 10px;
            top: 50%;
            transform: translateY( -50% );
            color: white
          } 
        }
      }

      // active rows (you) are red
      &.active
      {
        color: @color-hilite;
      }

      // fast lap is green
      &.pos-1 td,
      &.fast td
      {
        background: @color-fast-lap;
      }      

      // fasterst lap is purple
      &.fastest td
      {
        background: @color-fastest;
      }

      &.fastest.faster td[data-name="diff"]
      {
        color: white;
      }
    }

    td
    {
      // make room for the icon
      padding-left: 37px;
      position: relative;

      > div
      {
        display: inline-block;
        text-align: right;
      }

      // 2 characters wide
      &[data-name="pos"] > div
      {
        width: 1.2em;
      }

      // 4 characters wide
      &[data-name="penalty"] > div
      &[data-name="car"] > div
      {
        width: 2em;
      }

      // time diff, prefix with + or -
      &[data-name="diff"]
      {
        &[data-value^="-"]
        {
          color: @color-green;
        }
  
        &[data-value^="+"]
        {
          color: @color-orange;
        }
      }

      // car num / logo specifics
      &[data-name="car"] 
      {        
        position: relative;

        > div
        {
          // align numbers to the right
          text-align: right;
          width: 28px;

          // icon position relative to the TD, not the DIV
          .icon
          {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY( -50% );
            width: 20px;
            text-align: center;
            margin-top: -1px;

            &:before
            {
              display: block;
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }

  // animation for recently rows
  tr.recent
  {
    // fade in the row
    animation-name: fadein;
    animation-delay: .2s;
    animation-duration: .7s;
    animation-fill-mode: both;

    td:last-child:after
    {
      content: "";
      position: absolute;
      background: @color-green-bright;
      display: block;
      border-radius: 100%;
      width: 5px;
      height: 5px;
      z-index: 999;
      right: 15px;
      top: 50%;
      transform: translateY( -50% );
      border: 1px solid white;

      // fadeout the green dot
      animation-name: fadein;
      animation-direction: reverse;
      animation-delay: 4s;
      animation-duration: .7s;
      animation-fill-mode: both;
    }
  }

  // logo size tweaks
  .car-logo
  {
    &.icon-mini,
    &.icon-mazda,
    &.icon-peugeot,
    &.icon-citroen
    {
      &:before
      {
        font-size: .9em;
      }
    }
  }
}

@keyframes fadein
{
  0%
  {
    opacity: 0;   
  }

  100%	
  {
    opacity: 1;    
  }
}

@media screen and ( max-width: 600px )
{
  .data-table 
  {
    th:not([data-name="laptime"]) 
    {
      .label
      {
        display: none;
      }
    }

    td:not([data-name="laptime"]) 
    {
      padding-left: 10px;
    }

    tbody td[data-name="car"]
    {
      padding-left: 33px;
    }

    // align the star icon with the numbers below
    thead tr th[data-name="pos"] .icon
    {
      margin: 0 3px 0 7px;
    }

    // remove num 1 to make room for the icon
    tbody tr.fastest td[data-name="pos"] 
    {
      // align the stopwatch icon with the numbers in the samen column
      &:before
      {
        left: 16px;
      }

      div
      {
        display: none;
      }
    }

    tbody tr.recent td:last-child:after
    {
      right: 7px;
      width: 4px;
      height: 4px;
    }
  }
}

@media screen and ( max-width: 420px )
{
  .data-table
  {
    th .label
    {
      display: none;
    }

    tr td
    {
      padding-left: 10px;
    }    
  }

  // remove stopwatch icon, no room!  
  .carpage,
  .car-modal
  {
    .data-table tr.fastest td[data-name="laptime"]:before
    {
      display: none;
    }
  }
}