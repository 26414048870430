.summary
{
  text-align: center;
  padding: 0 2*@cell-padding;
  margin: @cell-padding auto 0;
  width: auto;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX( -50% );
  clear: both;

  .rs-panel-body
  {
    padding: 0;
  }

  span
  {    
    white-space: nowrap;
    margin: @cell-padding 0;
    display: inline-block;

    .icon
    {
      margin-right: .3em;
    }
  }

  .cars
  {
    b
    {
      color: @color-blue;
    }
  }

  .laps 
  {
    b
    {
      color: @color-hilite;
    }

    .icon
    {
      font-weight: 400;
    }
  }

  .cones 
  {
    b
    {
      color: @color-cone;
    }
  }

  .fast 
  {
    b
    {
      color: @color-fast-lap;
    }
  }

  .fastest 
  {
    b
    {
      color: @color-fastest;
    }
  }

  .icon
  {
    width: 12px;
    height: 12px;
    fill: @color-gray-dark;
  }
}